import styled from "styled-components";
import PropTypes from "prop-types";

/**
 * Icon
 */
export const Icon = styled.i.attrs(props => ({
  className: `fa fa-${props.name}`
}))`
  text-align: center;
  font-weight: ${props => props.theme.font_weight[props.fontWeight] || props.fontWeight || props.theme.font_weight.bold};
  color: ${props => props.theme.colors[props.color] || props.color || props.theme.colors.button_text};
  font-size: ${props => props.theme.font_size[props.fontSize] || props.fontSize || props.theme.font_size[5]};
  line-height: ${props => props.theme.space[props.lineHeight] || props.lineHeight || "inherit"};
  min-width: ${props => props.minWidth || "0px"};
`;

Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.any,
  fontSize: PropTypes.any,
  backgroundColor: PropTypes.any,
  minWidth: PropTypes.any,
  fontWeight: PropTypes.any,
  lineHeight: PropTypes.any,
};
