import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Flex} from "@rebass/grid";
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import {Preloader} from "../styled/others/Preloader";
import {Text, Title, Title1} from "../styled/text/Text";
import {Button} from "../styled/elements/Button";
import {fetchPlans} from "../../redux/plans/actions";
import {BackgroundBox} from "../styled/others/BackgroundBox";
import {Image} from "../styled/elements/Image";
import logo from "../../images/logo.png";
import {FixedBar} from "../styled/others/FixedBar";
import {Header} from "../styled/others/Header";
import {Icon} from "../styled/elements/Icon";
import {OfferBox} from "../styled/others/OfferBox";
import imageFitA from "../../images/fit-a.jpg";
import imageFitB from "../../images/fit-b.jpg";
import {RateBox} from "../styled/others/RateBox";

/**
 * Main route
 */
class IndexRoute extends Component {
  componentWillMount() {
    const {dispatch, plans} = this.props;
    !plans.size && dispatch(fetchPlans());
  }

  /**
   * on cancel
   * @return {Window | *}
   */
  onCancel = () => window && window.onCancel && window.onCancel();

  render() {
    const {plans, localized} = this.props;
    const {monthly, yearly, quarterly} = plans.reduce((all, plan) => {
      all[plan.chargedFrequencyDisplay] = plan;
      return all;
    }, {});

    return plans.size ? (
      <BackgroundBox>
        <AnimatedOnScroll animationIn="fadeIn" screenOffset={0}>
        <Box mx="auto" css={props => ({
          maxWidth: props.theme.maxWidth,
          position: "relative"
        })}>
          <FixedBar>
            <Box p={2}>
              <Header right={(
                <Button onClick={this.onCancel}>
                  <Icon name="times" fontSize={10} color="icon_light"/>
                </Button>
              )}/>
            </Box>
          </FixedBar>
          <Flex flexDirection="column" alignItems="center">
            <Box mb={4}>
                <Image src={logo} width="60px"/>
            </Box>
            <Box m={2}>
                <Title1 textTransform="uppercase" textAlign="center" fontSize={11} fontSizeXS={10}>
                  {localized.index.title}
                </Title1>
            </Box>
            <Box my={1} mx={2}>
                <Text fontSize={6} textAlign="center" lineHeight={1.6}>
                  {localized.index.subtitle}
                </Text>
            </Box>
            <Box mt={3}>
                <Flex alignItems="flex-end" justifyContent="center">
                  <OfferBox isLeft={true}
                            name={localized.index.monthly.interval}
                            price={monthly.priceMonthlyDisplayText}
                            description={`${monthly.priceDisplayText} ${localized.index.monthly.billed}`}
                            link={`/payment/${monthly.id}`}
                            localized={localized.index.offer_box}
                  />
                  <OfferBox isMid={true}
                            name={localized.index.yearly.interval}
                            price={yearly.priceMonthlyDisplayText}
                            description={`${yearly.priceDisplayText} ${localized.index.yearly.billed}`}
                            link={`/payment/${yearly.id}`}
                            localized={localized.index.offer_box}
                  />
                  <OfferBox isRight={true}
                            name={localized.index.quarterly.interval}
                            price={quarterly.priceMonthlyDisplayText}
                            description={`${quarterly.priceDisplayText} ${localized.index.quarterly.billed}`}
                            link={`/payment/${quarterly.id}`}
                            localized={localized.index.offer_box}
                  />
                </Flex>
            </Box>
              <Box mt={3}>
                <Text fontSize={6} textTransform="uppercase">
                  {localized.index.trial_text}
                </Text>
              </Box>
              <Box mt={1}>
                <Text fontSize={4}>
                  {localized.index.recurring_billing}
                </Text>
              </Box>
            <Box mt={4} mb={3}>
                <Title fontSize={8}>
                  {localized.index.success_stories.title}
                </Title>
            </Box>
              <Box mx={3} my={2} css={{
                overflow: "hidden",
                borderRadius: "4%"
              }}>
                <Image alt="fit girls" src={imageFitA} width="100%"/>
              </Box>
              <Box mx={3} my={2} css={{
                overflow: "hidden",
                borderRadius: "4%"
              }}>
                <Image alt="fit guys" src={imageFitB} width="100%"/>
              </Box>
            <Box>
              <Flex flexDirection="column" alignItems="center">
                <RateBox by="Jamie Lynn"
                         text={localized.index.success_stories.jamie_lynn} />
                <RateBox by="Kmignone1225"
                         text={localized.index.success_stories.kmignone} />
                <RateBox by="HollyDove12"
                         text={localized.index.success_stories.holly_dove} />
              </Flex>
            </Box>
            <Box p={3} my={3}>
                <Text fontSize={1} color="text_light" textAlign="center">
                  {localized.index.small_print}
                </Text>
            </Box>
            <Box p={3} my={3}>
              <a href="/" onClick={(event) => {
                event.preventDefault();
                window.onSwitcharoo();
              }}>
                <Text fontSize={1} color="text_light" textAlign="center">
                  {localized.index.switcharoo}
                </Text>
              </a>
            </Box>
          </Flex>
        </Box>
        </AnimatedOnScroll>
      </BackgroundBox>
    ) : (
      <Flex className="vh-for-mobile" alignItems="center" justifyContent="center" css={{
        height: "100vh"
      }}>
        <Box flex={1}>
          <Text textAlign="center">
            <Preloader size={4}/>
          </Text>
        </Box>
      </Flex>
    );
  }
}

export default connect(({plans: {plans, pending, error}}) => ({
  plans,
  pending,
  error
}))(IndexRoute);
