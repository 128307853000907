import React, {Component} from 'react';
import {css} from 'emotion';
import {Box} from "@rebass/grid";
import PropTypes from 'prop-types';
import {Header} from "../styled/others/Header";
import {Link} from "react-router-dom";
import {Icon} from "../styled/elements/Icon";
import {Button} from "../styled/elements/Button";

export class Card extends Component {
  hasHeader = () => {
    const {onCloseTo, titleText, onCloseCallback} = this.props;
    return onCloseCallback || onCloseTo || titleText;
  };

  renderOnClose = () => {
    const {onCloseTo, onCloseCallback} = this.props;
    if (onCloseTo) {
      return (
        <Link to={onCloseTo}>
          <Icon name="times" fontSize={10} color="icon_light"/>
        </Link>
      )
    }
    if (onCloseCallback) {
      return (
        <Button onClick={onCloseCallback}>
          <Icon name="times" fontSize={10} color="icon_light"/>
        </Button>
      )
    }
  };

  render() {
    const {titleText, paddingBottom, children} = this.props;
    const hasHeader = this.hasHeader();

    return (
      <div className={css`
              width: auto;
              background-color: #ffffff;
              border-radius: 5px;
              padding: 24px 16px;
              margin: 24px 16px;
              box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
              @media (max-width: 600px) {
                padding-bottom: ${paddingBottom};
              }
              ${hasHeader ? "padding-top:0" : ""}
        `}>
        <Box px={hasHeader ? 2 : 0} py={hasHeader ? 3 : 0}>
          <Header right={this.renderOnClose()}>
            {titleText ? (
              <span className={css`
                  font-family: 'Barlow Condensed',sans-serif;
                  font-size: 32px;
                  font-weight: bold;
                  font-style: normal;
                  font-stretch: condensed;
                  line-height: 1.25;
                  letter-spacing: normal;
                  text-align: center;
                  color: #000000;
                `}>
                {titleText}
              </span>
            ) : null}
          </Header>
        </Box>
        {children}
      </div>
    );
  }
}

Card.defaultProps = {
  children: null,
  titleText: null,
  paddingBottom: '16px',
  onCloseTo: "",
  onCloseCallback: null
};

Card.propTypes = {
  children: PropTypes.node,
  titleText: PropTypes.string,
  paddingBottom: PropTypes.string,
  onCloseTo: PropTypes.string,
  onCloseCallback: PropTypes.func
};
