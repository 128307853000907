import styled from "styled-components";
import PropTypes from "prop-types";

/**
 * mini preloader
 */
export const Preloader = styled.i.attrs({
  className: "fa fa-spinner fa-spin",
})`
  color: ${props => props.theme.colors[props.color] || props.color || props.theme.colors.preloader};
  font-size: ${props => props.theme.space[props.size] ? props.theme.space[props.size] + "px" : props.size};
`;

Preloader.propTypes = {
  size: PropTypes.any,
  color: PropTypes.any,
};
