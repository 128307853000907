import React, {Component} from 'react';
import {css} from 'emotion';

class Spinner extends Component {
  render() {
    return (
      <svg
        viewBox="0 0 24 24"
        className={css`
          width: 39px;
          height: 39px;

          -webkit-animation: spin 2s linear infinite;
          -moz-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;

          @-moz-keyframes spin {
            100% {
              -moz-transform: rotate(360deg);
            }
          }
          @-webkit-keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        `}
      >
        <g fill="#ffffff">
          <g className="nc-loop_circle-02-24" transform="rotate(360 12 12)">
            <path
              d="M12,24C5.3833008,24,0,18.6166992,0,12S5.3833008,0,12,0s12,5.3833008,12,12 S18.6166992,24,12,24z M12,2C6.4858398,2,2,6.4858398,2,12s4.4858398,10,10,10s10-4.4858398,10-10S17.5141602,2,12,2z"
              fill="#ffffff"
              opacity="0.4"
            />
            <path d="M24,12h-2c0-5.5141602-4.4858398-10-10-10V0C18.6166992,0,24,5.3833008,24,12z"/>
          </g>
        </g>
      </svg>
    );
  }
}

Spinner.defaultProps = {};
Spinner.propTypes = {};

export default Spinner;
