import React, {Component} from "react";
import {Box} from "@rebass/grid";
import Measure from "react-measure";
import PropTypes from "prop-types";

export class FixedBar extends Component {
  state = {
    height: 0
  };

  render() {
    const {css, noMeasure, children} = this.props;
    const {height} = this.state;
    return (
      <Box css={{
        height: noMeasure ? "auto" : `${height}px`,
      }}>
        <Measure bounds onResize={contentRect => {
          this.setState({height: contentRect.bounds.height})
        }}>
          {({measureRef}) => (
            <Box ref={measureRef} css={props => ({
              maxWidth: "100%",
              width: props.theme.maxWidth,
              position: "absolute",
              zIndex: 2,
              backgroundColor: props.theme.colors.background,
              ...css
            })}>
              {children}
            </Box>
          )}
        </Measure>
      </Box>
    )
  }
}


FixedBar.propTypes = {
  css: PropTypes.any,
  noMeasure: PropTypes.bool
};
