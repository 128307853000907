import React from 'react';
import {css} from 'emotion';

const SaveCoupon = props => (
  <svg
    width="80px"
    height="20px"
    className={css`
      position: absolute;
      top: 20px;
      left: 75px;
    `}
  >
    <defs>
      <linearGradient
        id="a"
        x1="6.493%"
        x2="66.758%"
        y1="120.338%"
        y2="-53.533%"
      >
        <stop offset="0%" stopColor="#F0006C"/>
        <stop offset="100%" stopColor="#FF1D1D"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#111213"
        fillOpacity=".2"
        d="M60.331 14.045c0-1.854 1.418-3.273 3.273-3.273V5.318h-59v5.454c1.854 0 3.273 1.419 3.273 3.273 0 1.855-1.419 3.273-3.273 3.273v5.454h59v-5.454c-1.855 0-3.273-1.418-3.273-3.273z"
      />
      <path
        fill="url(#a)"
        d="M60.727 8.727c0-1.854 1.418-3.272 3.273-3.272V0H0v5.455c1.855 0 3.273 1.418 3.273 3.272C3.273 10.582 1.855 12 0 12v5.455h64V12c-1.855 0-3.273-1.418-3.273-3.273z"
        transform="translate(.993 .526)"
      />
      <text
        fill="#FFF"
        fontFamily="Barlow-Bold, Barlow"
        fontSize="12"
        fontWeight="bold"
        letterSpacing="-.3"
        transform="translate(.993 .526)"
      >
        <tspan x="6.927" y="12.305">
          {props.save}
        </tspan>
        {' '}
        <tspan x="48.675" y="12.305">
          %
        </tspan>
      </text>
    </g>
  </svg>
);

export default SaveCoupon;
