import {createBrowserHistory} from "history";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {configureStore} from "./configureStore";
import initialState from "./initialState";
import reducers from "./reducers";
import middleware from "./middleware";

// web browser history
export const history = createBrowserHistory();

/**
 * create store by extending the shared/redux/configureStore
 * @param onComplete
 */
export default onComplete => configureStore(onComplete, {
  ...initialState
}, {
  ...reducers,
  router: connectRouter(history)
}, [
  ...middleware,
  routerMiddleware(history)
]);
