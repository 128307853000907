import {fromJS, Iterable, Record, Seq} from "immutable";

export const recordFromJS = (js = {}) => {
  try {
    if (typeof js !== 'object' || js === null) {
      return js;
    }

    if (Iterable.isIterable(js)) {
      return js;
    }

    if (js.size) {
      // size is not valid property in immutable js
      js._size = js.size;
      delete js.size;
    }

    if (Array.isArray(js)) {
      return Seq(js).map(recordFromJS).toList();
    }

    return new (Record(js))(Seq(js).map(recordFromJS));
  } catch (e) {
    console.log(js, e);
  }
};


/**
 * convert javascript object to immutable map
 * @param js
 * @return {*}
 */
export const mapFromJS = js => fromJS(js || {});


/**
 * convert javascript object to immutable record
 * @return {*}
 * @param record
 */
export const mapFromRecord = record => mapFromJS(record && record.toJS ? record.toJS() : record || {});


/**
 * Deep marge record
 */

export const mergeDeepRecord = (record, data) => recordFromJS(mapFromRecord(record).mergeDeep(mapFromRecord(data)).toJS());

/**
 * remove pending state from state pending array
 * @param state
 * @param id
 */
export const removeStateStatus = (state, id) => state.set("status", state.status.filter(item => item.id !== id));

/**
 * push state status into state status array
 * @param state
 * @param id
 * @param {string} status
 * @param {Object} data
 */
export const setStateStatus = (state, id, status, data = {}) => state.set("status", state.status
  .filter(item => item.id !== id)
  .push(recordFromJS({id, status, data})));

/**
 * remove state status from state status array using statusCode
 * @param state
 * @param statusCode
 */
export const removeStateStatusByCode = (state, statusCode) => state.set("status", state.status
  .filter(item => !(item.data && item.data && item.data.status === statusCode)));


/**
 * sort by
 * @param a
 * @param b
 * @param by
 * @return {number}
 */
export const sortListBy = (a, b, by = "created") => a[by] === b[by] ? 0 : (a[by] < b[by] ? 1 : -1);

/**
 * merge list with new list and resolve previous for load more
 * @param list
 * @param previous
 * @param newList
 * @param pageSize
 * @param sortBy
 * @return {*}
 */
export const mergeStateListAndPrevious = (list, newList, previous, sortBy = "created", pageSize = 10) => {
  if (newList) {
    newList = newList.sort((a, b) => sortListBy(a, b, sortBy));
    let newPrevious = null;
    if (newList.size === pageSize + 1) {
      newPrevious = newList.last();
      newList = newList.pop();
    }
    if (newPrevious) {
      previous = list.find(item => item.id === newPrevious.id) ? previous : newPrevious;
    } else {
      previous = newPrevious;
    }
    // remove old list in the new range or before previous
    const firstNew = newList.first();
    const lastNew = newList.last();
    list = list
      .filter(item =>
        (item[sortBy] > firstNew[sortBy] || item[sortBy] < lastNew[sortBy]) &&
        (!previous || item[sortBy] > previous[sortBy]) &&
        (!newList.some(newItem => newItem.id === item.id))
      )
      .concat(newList)
      .sort((a, b) => sortListBy(a, b, sortBy));
    // return all
    return {
      previous,
      list
    };
  }
  return {
    previous,
    list
  };
};
