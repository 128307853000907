import styled from "styled-components";
import PropTypes from "prop-types";

/**
 * button
 */
export const Link = styled.a`
  margin: 0;
  padding: 0;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  transition: 0.2s all;
  border: none; 
  line-height: ${props => props.theme.space[props.lineHeight] || props.lineHeight || "inherit"};
  font-weight: ${props => props.theme.font_weight[props.fontWeight] || props.fontWeight || props.theme.font_weight.normal};
  color: ${props => props.theme.colors[props.color] || props.color || "inherit"};
  font-size: ${props => props.theme.font_size[props.fontSize] || props.fontSize || "inherit"};
  background-color: ${props => props.theme.colors[props.backgroundColor] || props.backgroundColor || props.theme.colors.button};
  min-width: ${props => props.minWidth || "0px"};
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
  border: ${props => props.border || "none"};
  :hover, :active, :focus {
    text-decoration: underline;    
    color: ${props => props.theme.colors[props.colorHover] || props.colorHover || props.theme.colors.button_hover};
    opacity: 0.8;
    p {
       color: ${props => props.theme.colors[props.colorHover] || props.colorHover || props.theme.colors.button_hover};         
    }
  }
  &[disabled] {
    opacity: 0.9; !important;
    cursor: default;
  }
`;

Link.propTypes = {
  color: PropTypes.any,
  colorHover: PropTypes.any,
  fontSize: PropTypes.any,
  backgroundColor: PropTypes.any,
  minWidth: PropTypes.any,
  fontWeight: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  border: PropTypes.any
};
