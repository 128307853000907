import React, {Component} from 'react';
import {css} from 'emotion';
import {connect} from "react-redux";
import {Box, Flex} from "@rebass/grid";
import {replace} from "connected-react-router"
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe} from 'react-stripe-elements';

import Button from '../subscriptionNewVersion/button';
import MonthlyYearlyButton from '../subscriptionNewVersion/newSubscribeButtons';
import CVVinfoIcon from '../subscriptionNewVersion/CVVinfoIcon';
import InfoIcon from '../subscriptionNewVersion/infoIcon';
import {Icon} from '../styled/elements/Icon';
import {fetchPlans} from "../../redux/plans/actions";
import {Text} from "../styled/text/Text";
import {Preloader} from "../styled/others/Preloader";
import {Card} from "../subscriptionNewVersion/card";
import {submitStripe, validateCoupon} from "../../redux/api";

const stripeOptions = {
  style: {
    base: {
      color: '#000000',
      fontWeight: 400,
      fontFamily: 'Barlow Regular, sans-serif',
      fontSize: '17px',
      '::placeholder': {
        color: '#000000',
      },
    },
    invalid: {
      color: '#f00',
    }
  }
};

const stripeOptionsCardNumber = {
  style: {
    base: {
      fontSize: '17px',
      fontFamily: '"Lato"'
    }
  }
};

/**
 * get dollars
 * @param cents
 * @param currency
 * @param discount
 * @return {string}
 */
const getDollars = (cents, currency, discount) => {
  let discountAmount;
  if (discount) {
    discountAmount = discount.percentOff ? cents * (discount.percentOff / 100) : discount.amountOff;
  }
  const num = (discountAmount ? cents - discountAmount : cents) / 100;
  return num === 0 ? 'FREE' : num.toLocaleString('en-US', {
    style: 'currency',
    currency: currency.toUpperCase(),
  });
};

class Payment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showPromoField: false,
      promoCode: null,
      loading: false,
      paymentError: null,
      appliedPromoCode: null,
      displayCardInfo: false,
      formErrorCardNumber: true,
      formErrorCvv: true,
      formErrorExpiry: true,
      discountDuration: undefined,
      showChangeSubscription: false,
      planId: null,
    };
  }

  componentDidMount() {
    const {dispatch, plans, match} = this.props;
    !plans.size && dispatch(fetchPlans());
    if (match && match.params && match.params.planId) {
      this.setState({planId: match.params.planId});
    }
  }

  componentDidUpdate({match: prevMatch}) {
    const {match} = this.props;
    if (match && match.params && match.params.planId && (!prevMatch || !prevMatch.params || prevMatch.params.planId !== match.params.planId)) {
      this.setState({planId: match.params.planId});
    }
  }

  handleToken = ({complete, token, error}) => {
    const {planId, appliedPromoCode} = this.state;

    // user access token
    const accessToken = window && window.getAccessToken && window.getAccessToken();
    //device advertiser id
    const advertiserId = window && window.getAdvertiserId && window.getAdvertiserId();
    return error || !token ? Promise.reject(error || new Error()) : submitStripe({
      planId,
      stripeToken: token.id,
      couponCode: appliedPromoCode && appliedPromoCode.id ? appliedPromoCode.id : '',
      advertiserId,
      accessToken
    }).then(result => {
      if (complete) {
        complete('success');
      }
      const selectedPlan = this.getSelectedPlan();
      if (window.AnalyticsWebInterface && window.AnalyticsWebInterface.logEvent) {
        let interval = "monthly";
        if (selectedPlan.interval === "month" && selectedPlan.intervalCount === 3) {
          interval = "quarterly";
        } else if (selectedPlan.interval === "year") {
          interval = "annual";
        }
        const data = JSON.stringify({
          source: "webview",
          planId,
          interval,
          amount: selectedPlan.amount,
          currency: selectedPlan.currency,
        });
        // Call Android interface
        window.AnalyticsWebInterface.logEvent(`${interval}_trial_started`, data);
        window.AnalyticsWebInterface.logEvent(`user_trial_started`, data);
      }
      this.setState({loading: false});
      window && window.onDone && window.onDone(result);
    })
  };

  handleSubmit = e => {
    e.preventDefault();

    const {stripe} = this.props;

    // show loading before submit
    this.setState({loading: true, paymentError: null});
    stripe
      .createToken()
      .then(this.handleToken)
      .catch(err => this.setState({loading: false, paymentError: err}));
  };

  // Make API call to validate promo code.
  validatePromoCode = promoCode => validateCoupon(promoCode)
    .then(result => this.setState({
      promoCodeInvalid: false,
      appliedPromoCode: result,
      discountDuration: result.durationInMonths,
      showPromoField: false,
    }))
    .catch(() => this.setState({promoCodeInvalid: true, appliedPromoCode: null}));

  displayAmountDueAfterTrial = () => {
    const {planId, appliedPromoCode} = this.state;
    const {plans} = this.props;
    const selectedPlan = plans.find(({id}) => id === planId);
    const {amount, currency, chargedFrequencyDisplay, priceDisplayText} = selectedPlan || {};
    const {percentOff, amountOff, durationInMonths} = appliedPromoCode || {};
    return durationInMonths && chargedFrequencyDisplay !== 'monthly' ? priceDisplayText : `${getDollars(amount, currency, {
      percentOff,
      amountOff,
    })}`;
  };

  displayCouponDiscount = () => {
    const {duration, durationInMonths, percentOff, amountOff} = this.state.appliedPromoCode || {};
    if (duration === 'once') {
      return amountOff ? `${amountOff / 100} USD OFF` : `${percentOff}% OFF`;
    }
    if (duration === 'repeating' && durationInMonths) {
      return amountOff
        ? `${amountOff / 100} USD OFF FOR ${durationInMonths} MONTHS`
        : `${percentOff}% OFF FOR ${durationInMonths} MONTHS`;
    }
    if (duration === 'forever') {
      return amountOff
        ? `${amountOff / 100} USD OFF FOREVER`
        : `${percentOff}% OFF FOREVER`;
    }
    return null;
  };

  togglePromoField = () => this.setState({showPromoField: !this.state.showPromoField});

  // Set currently typed pc text in state and remove pc error.
  changePromoCode = e => {
    const promoCode = e.target.value;
    this.setState({
      promoCode,
      promoCodeInvalid: false,
      pendingPromoValidation: false,
    });
  };

  // Apply the promo code that is in state (it will be sent with payment submission).
  applyPromoCode = () => {
    this.setState({pendingPromoValidation: true});
    // Extra step here to avoid trying to apply empty pc or pc already detemined to be invalid.
    if (this.state.promoCode && !this.state.promoCodeInvalid) this.validatePromoCode(this.state.promoCode);
  };

  toggleChangeSubscription = () => this.setState({
    showChangeSubscription: !this.state.showChangeSubscription
  });

  changeSubscription = planId => {
    this.props.dispatch(replace(`/payment/${planId}`));
    this.setState({
      showChangeSubscription: false
    });
  };

  getSelectedPlan = () => {
    const {planId} = this.state;
    const {plans} = this.props;
    return plans.size && planId ? plans.find(({id}) => id === planId) : null;
  };

  mouseEnter = () => this.setState({displayCardInfo: true});

  mouseLeave = () => this.setState({displayCardInfo: false});

  handleTouchToggleIcon = () => this.setState({displayCardInfo: !this.state.displayCardInfo});

  handleOnBlur = () => this.setState({displayCardInfo: false});

  render() {
    const {
      discountDuration,
      formErrorCardNumber,
      formErrorCvv,
      formErrorExpiry,
      displayCardInfo,
      showPromoField,
      promoCode,
      pendingPromoValidation,
      promoCodeInvalid,
      paymentError,
      loading,
      showChangeSubscription,
    } = this.state;
    const {plans, localized} = this.props;
    const selectedPlan = this.getSelectedPlan();
    const promoCodeException = selectedPlan && discountDuration && selectedPlan.chargedFrequencyDisplay !== 'monthly';
    const formIsValid = !selectedPlan || !(formErrorCardNumber || formErrorCvv || formErrorExpiry || promoCodeException);

    return selectedPlan ? (
      <Box mx="auto" css={props => ({
        maxWidth: props.theme.maxWidth,
        position: "relative"
      })}>
        {showChangeSubscription ? (
          <Card paddingBottom="0px" titleText={localized.payment.change_subscription_titletext} onCloseCallback={this.toggleChangeSubscription}>
            <p className={css`
                      font-family: Barlow,sans-serif;
                      font-size: 17px;
                      font-weight: normal;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: 1.41;
                      letter-spacing: normal;
                      text-align: center;
                      color: #000000;
                      margin-bottom: 24px;
                      `}
            >
              {localized.payment.select_subscription}
            </p>
            {plans.map(plan => (
              <div key={`plan-${plan.id}`}
                   className={css`
                        margin-bottom: 17px;
                        display: flex;
                        justify-content: center;
                        width: auto;
                      `}>
                <MonthlyYearlyButton selected={plan.id === selectedPlan.id}
                                     text={plan.intervalDisplayText}
                                     subText={plan.intervalDisplaySubText}
                                     currency={selectedPlan.currency}
                                     price={plan.priceDisplayText}
                                     handleClick={() => this.changeSubscription(plan.id)}/>
              </div>
            ))}
          </Card>
        ) : null}
        <Box css={{
          display: showChangeSubscription ? "none" : "block"
        }}>
          <Card paddingBottom="0px" titleText={localized.payment.title} onCloseTo={"/"}>
            <form onSubmit={this.handleSubmit} className="injected-form__wrapper">
              <div className={css`
                    display: flex;
                    justify-content: center;
                `}>
                <MonthlyYearlyButton selected={true}
                                     text={selectedPlan.intervalDisplayText}
                                     subText={selectedPlan.intervalDisplaySubText}
                                     price={selectedPlan.priceDisplayText}
                                     currency={selectedPlan.currency}
                                     handleClick={this.toggleChangeSubscription}/>
              </div>
              <p className={css`
                  color: #12b587;
                  text-align: center;
                  margin-top: 10px;
                  font-family: 'Barlow',sans-serif;
                  font-size: 14px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  cursor: pointer;
                `}
                 tabIndex={0}
                 role="button"
                 onClick={this.toggleChangeSubscription}
                 onKeyPress={this.toggleChangeSubscription}>
                <Icon name="pencil-alt"
                      color="#12b587"
                      className={css`
                      margin-right: 8px;
                    `}/>
                {localized.payment.change_plan}
              </p>
              <div className={css`
                  width: 100%;
                  @media (min-width: 768px) {
                    width: calc(100% - 80px);
                    margin-left: 40px;
                  }
                `}>
                <section className={css`
                    width: 100%;
                `}>
                  <div className={css`
                      border-bottom: 1px solid rgba(58, 59, 62, 0.5);
                      padding: 8.5px;
                      margin-top: 16px;
                    `}>
                    <CardNumberElement {...stripeOptionsCardNumber}
                                       onChange={e => this.setState({formErrorCardNumber: e.error ? e.error.message : null})}/>
                  </div>
                  <p className={css`
                      font-family: Barlow,sans-serif;
                      font-size: 12px;
                      font-weight: normal;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: 1.5;
                      letter-spacing: normal;
                      color: #ff0000;
                    `}>
                    {formErrorCardNumber}
                    <span className={css`
                        color: white;
                      `}>
                      &nbps;&nbps;&nbps;
                </span>
                  </p>
                </section>
                <div className={css`
                    display: flex;
                    margin-top: 24px;
                  `}>
                  <section className={css`
                      width: 50%;
                    `}>
                    <div className={css`
                        border-bottom: 1px solid rgba(58, 59, 62, 0.5);
                        margin-right: 16px;
                        padding: 8.5px;
                      `}>
                      <CardExpiryElement {...stripeOptions}
                                         placeholder={localized.payment.expiration_date_placeholder}
                                         onChange={e => this.setState({formErrorExpiry: e.error ? e.error.message : null})}/>
                    </div>
                    <p className={css`
                        font-family: Barlow,sans-serif;
                        font-size: 12px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #ff0000;
                      `}>
                      {formErrorExpiry}
                      <span className={css`
                            color: white;
                           `}>
                        &nbps;&nbps;&nbps;
                    </span>
                    </p>
                  </section>
                  <section className={css`
                      width: 50%;
                    `}>
                    <div className={css`
                        padding: 8.5px;
                        border-bottom: 1px solid rgba(58, 59, 62, 0.5);
                        position: relative;
                      `}>
                      <CardCVCElement {...stripeOptions}
                                      placeholder={localized.payment.cvv_placeholder}
                                      onChange={e => this.setState({formErrorCvv: e.error ? e.error.message : null})}/>
                      <span className={css`
                          position: absolute;
                          top: 11px;
                          right: 0;
                        `}
                            onMouseEnter={this.mouseEnter}
                            onMouseLeave={this.mouseLeave}
                            onTouchStart={this.handleTouchToggleIcon}
                            onBlur={this.handleOnBlur}>
                        <InfoIcon/>
                  </span>
                    </div>
                    <p className={css`
                        font-family: Barlow,sans-serif;
                        font-size: 12px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #ff0000;
                      `}>
                      {formErrorCvv}
                      <span className={css`
                          color: white;
                        `}>
                        &nbps;&nbps;&nbps;
                    </span>
                    </p>
                  </section>
                </div>
                {displayCardInfo ? (
                  <div className={css`
                      z-index: 10;
                      background-color: #ffffff;
                      position: absolute;
                      right: 50px;
                      height: 122px;
                      border-radius: 5px;
                      box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
                    `}>
                    <CVVinfoIcon/>
                  </div>
                ) : null}
                {showPromoField ? (
                  <React.Fragment>
                    <div className={css`
                        display: flex;
                        border-bottom: 1px solid rgba(58, 59, 62, 0.5);
                        justify-content: space-between;
                        margin-top: 0;
                      `}>
                      <input placeholder={localized.payment.promo_placeholder}
                             onChange={this.changePromoCode}
                             className={css`
                                width: 75%;
                                border: 0;
                                font-family: Barlow,sans-serif;
                                font-size: 17px;
                                font-weight: normal;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: 1.41;
                                letter-spacing: normal;
                                color: #000000;
                                margin-top: 24px;
                                -webkit-touch-callout: none; /* iOS Safari */
                                -webkit-user-select: none; /* Safari */
                                -khtml-user-select: none; /* Konqueror HTML */
                                -moz-user-select: none; /* Firefox */
                                -ms-user-select: none; /* Internet Explorer/Edge */
                                user-select: none;
                                padding: 8.5px;
                                ::placeholder {
                                  color: #000000;
                                }
                            `}/>
                      {!promoCode || pendingPromoValidation ? (
                        <p className={css`
                            font-family: Barlow,sans-serif;
                            color: #3a3b3e;
                            padding-right: 8.5px;
                            margin-top: 34px;
                          `}>
                          {localized.payment.promo_apply}
                        </p>
                      ) : (
                        <p className={css`
                            font-family: Barlow,sans-serif;
                            color: #12b587;
                            cursor: pointer;
                            padding-right: 8.5px;
                            margin-top: 34px;
                          `}
                           tabIndex={0}
                           role="button"
                           onClick={this.applyPromoCode}
                           onKeyPress={this.applyPromoCode}>
                          {localized.payment.apply}
                        </p>
                      )}
                    </div>
                    <p className={css`
                        font-family: Barlow,sans-serif;
                        font-size: 12px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #ff0000;
                      `}>
                      {promoCodeInvalid ? localized.payment.invalid_promo : ' '}
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p tabIndex={0}
                       role="button"
                       onClick={this.togglePromoField}
                       onKeyPress={this.togglePromoField}
                       className={css`
                      color: #12b587;
                      text-align: left;
                      margin-top: 24px;
                      cursor: pointer;
                  `}>
                      {promoCode ? (
                        <span>
                          <Icon name="pencil"/>{' '}
                          <span className={css`
                                color: #000000;
                                font-family: Barlow,sans-serif;
                              `}>
                              {promoCode}
                          </span>
                        </span>
                      ) : localized.payment.promo_code}
                    </p>
                    <p className={css`
                        margin-top: 5px;
                        color: #757575;
                        font-family: Barlow,sans-serif;
                      `}>
                      {this.displayCouponDiscount()}
                    </p>
                    {promoCodeException ? (
                      <p className={css`
                          color: #fc172f;
                          font-family: Barlow,sans-serif;
                        `}>
                        {localized.payment.coupon_only_monthly}
                      </p>
                    ) : null}
                  </React.Fragment>
                )}
                {paymentError ? (
                  <p className={css`
                      font-family: Barlow,sans-serif;
                      font-size: 12px;
                      font-weight: normal;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: 1.5;
                      letter-spacing: normal;
                      color: #ff0000;
                  `}>
                    {paymentError === 'User already has a subscription' ?
                      "You already have a subscription." :
                      "Your credit card was not accepted."}
                  </p>
                ) : null}
                <p className={css`
                    font-family: Barlow,sans-serif;
                    font-size: 21px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    color: #000000;
                    margin-top: 20px;
                    margin-bottom: 18px;
                  `}>
                  {localized.payment.total_due}
                </p>
                <p className={css`
                    font-family: Barlow,sans-serif;
                    font-size: 17px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.41;
                    letter-spacing: normal;
                    color: #000000;
                    margin-bottom: 20px;
                    @media (min-width: 768px) {
                      margin-bottom: 40px;
                    }
                  `}>
                  {selectedPlan.chargedFrequencyDisplay === 'monthly' ?
                    localized.payment.charged_monthly
                    : null}
                  {selectedPlan.chargedFrequencyDisplay === 'quarterly' ?
                    localized.payment.charged_quarterly
                    : null}
                  {selectedPlan.chargedFrequencyDisplay === 'yearly' ?
                    localized.payment.charged_annually
                    : null}
                </p>
              </div>
              <div className={css`
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  @media (max-width: 600px) {
                    position: sticky;
                    margin-left: -16px;
                    bottom: 0;
                    border-radius: 5px;
                    padding: 16px 16px;
                    background-color: #ffffff;
                    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
                    z-index: 100;
                  }
                `}>
                <Button buttonText={localized.payment.subscribe_now}
                        buttonState={loading ? 'loading' : (formIsValid ? 'default' : 'disabled')}/>
              </div>
            </form>
          </Card>
        </Box>
      </Box>
    ) : (
      <Flex className="vh-for-mobile" alignItems="center" justifyContent="center" css={{
        height: "100vh"
      }}>
        <Box flex={1}>
          <Text textAlign="center">
            <Preloader size={4}/>
          </Text>
        </Box>
      </Flex>
    );
  }
}

export default connect(({plans: {plans, pending, error}}) => ({
  plans,
  pending,
  error
}))(injectStripe(Payment));
