import styled, {css} from "styled-components";
import PropTypes from "prop-types";

/**
 * button
 */
export const Button = styled.button`
  margin: 0;
  padding: 0;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: 0.2s all;
  border: none; 
  line-height: ${props => props.theme.space[props.lineHeight] || props.lineHeight || "inherit"};
  font-weight: ${props => props.theme.font_weight[props.fontWeight] || props.fontWeight || props.theme.font_weight.bold};
  color: ${props => props.theme.colors[props.color] || props.color || props.theme.colors.button_text};
  font-size: ${props => props.theme.font_size[props.fontSize] || props.fontSize || props.theme.font_size[3]};
  background-color: ${props => props.theme.colors[props.backgroundColor] || props.backgroundColor || props.theme.colors.button};
  min-width: ${props => props.minWidth || "0px"};
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
  border: ${props => props.border || "none"};
  ${props => props.circle && css`
     border-radius: 50%;
  `}
  :hover, :active, :focus {
    text-decoration: none;    
    color: ${props => props.theme.colors[props.colorHover] || props.colorHover || props.theme.colors.button_hover};
    opacity: 0.8;
    p {
       color: ${props => props.theme.colors[props.colorHover] || props.colorHover || props.theme.colors.button_hover};         
    }
  }
  &[disabled] {
    opacity: 0.9; !important;
    cursor: default;
  }
`;

Button.propTypes = {
  color: PropTypes.any,
  fontSize: PropTypes.any,
  backgroundColor: PropTypes.any,
  minWidth: PropTypes.any,
  fontWeight: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  circle: PropTypes.bool,
  colorHover: PropTypes.any,
  border: PropTypes.any
};


/**
 * button plain
 */
export const ButtonPlain = styled.button`
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s opacity;
  border: none;
  padding: 0;
  margin: 0;
  width: ${props => props.width || "auto"};
  background-color: ${props => props.theme.colors[props.backgroundColor] || props.backgroundColor || props.theme.colors.button};
  &.active, :hover, :active, :focus {
    text-decoration: none;
    opacity: 0.8;
  }
`;

ButtonPlain.propTypes = {
  backgroundColor: PropTypes.any,
  width: PropTypes.any,
  minWidth: PropTypes.any,
};
