import React from "react";
import {Link} from "./styled/elements/Link";
import {Button} from "./styled/elements/Button";

export default {
  en: {
    index: {
      title: "Become Your Fittest Self",
      subtitle: "Unlock the secret routines of top athletes with video & tracking",
      trial_text: "Includes a 7 day free trial",
      recurring_billing: "Recurring billing. Cancel anytime.",
      start_free: "Start FREE",
      continue: "Continue",
      success_stories: {
        title: "Fitplan Success Stories",
        jamie_lynn: "It should be recognized that the workout plans implemented in this app are done by highly qualified fitness professionals",
        kmignone: "I don't usually rate apps, but I really felt the need to with this one...Best I've done in a long time and I've tried many plans and fitness apps",
        holly_dove: "Loving it!..Recommend this to anyone who needs a new workout routine and needs to mix it up!"
      },
      monthly: {
        billed: "billed monthly",
        interval: "Monthly",
      },
      yearly: {
        billed: "billed yearly",
        interval: "Yearly",
      },
      quarterly: {
        billed: "billed quarterly",
        interval: "Quarterly",
      },
      offer_box: {
        most_popular: "Most Popular",
        month: "mo"
      },
      switcharoo: "Click here to pay through Google Play Store",
      small_print: (
        <>
          Subscriptions renew within 24 hours before the subscription period ends. By continuing you accept our billing terms,
          <Link href="https://www.fitplanapp.com/privacy-policy" target="_blank">Privacy Policy</Link>
          , and <Link href="https://www.fitplanapp.com/terms-of-service" target="_blank">Terms of Service.</Link>
        </>
      )
    },
    payment: {
      title: "Enter Payment",
      select_subscription: "Select subscription type to proceed",
      apply: "Apply",
      coupon_only_monthly: "This coupon code is only valid on monthly plan",
      total_due: "Total due after trial:",
      charged_monthly: "You won't be charged until your 7-day free trial ends. You can cancel at any time. Your account will be charged monthly until you cancel your subscription.",
      charged_quarterly: "You won't be charged until your 7-day free trial ends. You can cancel at any time. Your account will be charged quarterly until you cancel your subscription.",
      charged_annually: "You won't be charged until your 7-day free trial ends. You can cancel at any time. Your account will be charged annually until you cancel your subscription.",
      promo_placeholder: "Enter promo code",
      promo_code: "Enter promo code",
      promo_apply: "Apply",
      invalid_promo: "Invalid Promo Code",
      change_subscription_titletext: "Change Subscription",
      subscribe_now: "SUBSCRIBE NOW",
      change_plan: "Change",
      expiration_date_placeholder: "MM/YY",
      cvv_placeholder: "CVV",
    },
    error: {
      title: "Page Not Found",
      does_not_exist: "Sorry, the page you are looking for does not exist.",
      return_link: (
        <>
          You can always go back to the <Button as={Link} to="/" fontSize={8}>homepage</Button>.
        </>
      )
    },
    billed: {
      quarterly: "Billed once per quarter",
      quarterly_interval: "Quarterly",
      yearly: "Billed once per year",
      yearly_interval: "Yearly",
      monthly: "Billed once a month",
      monthly_interval: "Monthly",
    }
  },
  es: {
    index: {
      title: "Se la versión más esbelta de ti mismo",
      subtitle: "Desbloquee las rutinas secretas de los mejores atletas con video HD y seguimiento de repeticiones",
      trial_text: "Incluye un período de prueba de 7 días",
      recurring_billing: "Cambia o cancele su plan cuando quiera",
      success_stories: {
        title: "Fitplan Historias de Éxito",
        jamie_lynn: "Los entrenamientos en la app están diseñados por profesionales de fitness calificados",
        kmignone: "Normalmente no califico apps pero sentí que tenía que hacerlo con esta. ...Es la mejor que he utilizado en mucho tiempo y eso que he intentado muchos planes y apps de fitness.",
        holly_dove: "Me encanta!..Recomiendo esta app a todos los que necesiten una nueva rutina de entrenamiento con mucha variedad!"
      },
      monthly: {
        billed: "cada al mes",
        interval: "MENSUAL",
      },
      yearly: {
        billed: "anual",
        interval: "ANUAL",
      },
      quarterly: {
        billed: "cada 3 meses",
        interval: "TRIMESTRAL",
      },
      offer_box: {
        most_popular: "Más Popular",
        month: "mes"
      },
      switcharoo: "Haz clic aquí para pagar a través de Google Play Store",
      small_print: (
        <>
          Las subscripciones se renuevan dentro de 24 horas antes de que se finalice el periodo de subscripción. Al continuar, aceptas nuestros términos de facturación,
          <Link href="https://es.fitplanapp.com/privacy-policy" target="_blank">Política de Privacidad</Link>
          , y <Link href="https://es.fitplanapp.com/terms-of-service" target="_blank">Términos de Servicio.</Link>
        </>
      )
    },
    payment: {
      title: "Introducir pago",
      select_subscription: "Escoge tu periodo de suscripción",
      apply: "Aplicar",
      coupon_only_monthly: "Este código de cupón sólo es válido en el plan mensual",
      total_due: "Cantidad a pagar después de la prueba:",
      charged_monthly: "No serás cobrado hasta finalizar el periodo de prueba de 7 días. Puedes cancelarla en cualquier momento. Tu cuenta será cargada mensualmente hasta que canceles tu subscripción.",
      charged_quarterly: "No serás cobrado hasta finalizar el periodo de prueba de 7 días. Puedes cancelarla en cualquier momento. Tu cuenta será cargada trimestralmente hasta que canceles tu subscripción.",
      charged_annually: "No serás cobrado hasta finalizar el periodo de prueba de 7 días. Puedes cancelarla en cualquier momento. Tu cuenta será cargada anualmente hasta que canceles tu subscripción.",
      promo_placeholder: "Ingresar código de promoción",
      promo_code: "Código de promoción",
      promo_apply: "Aplicar",
      change_subscription_titletext: "Cambia un subscripcion",
      subscribe_now: "SUBSCRIBETE",
      change_plan: "Cambia",
      expiration_date_placeholder: "MM/AA",
      cvv_placeholder: "CVV",
    },
    error: {
      title: "Página no encontrada",
      does_not_exist: "Lo sentimos, una página no existe.",
      return_link: (
        <>
          Regresar a la <Button as={Link} to="/" fontSize={8}>página principal</Button>.
        </>
      )
    }
  }
};

