import React from "react";
import PropTypes from "prop-types";
import {Box, Flex} from "@rebass/grid";
import {Title} from "../text/Text";

/**
 * header
 */
export const Header = React.forwardRef(({left, right, children, ...res}, ref) => (
  <Flex alignItems="center" justifyContent="center" flexDirection="row" ref={ref} {...res}>
    <Box p={2} css={{textAlign: "left"}} flex={1}>
      {left}
    </Box>
    <Box p={2}>
      <Title as={"h1"} textAlign="center" fontSize={5}>
        {children}
      </Title>
    </Box>
    <Box p={2} css={{textAlign: "right"}} flex={1}>
      {right}
    </Box>
  </Flex>
));

Header.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
  title: PropTypes.any
};
