import styled, {css} from "styled-components";
import PropTypes from "prop-types";

/**
 * text
 */
export const Text = styled.p`
  margin: 0;
  transition: font-size .5s ease;
  line-height: ${props => props.lineHeight || 1.3};
  font-size: ${props => props.theme.font_size[props.fontSize] || props.fontSize || props.theme.font_size[2]};
  font-weight: ${props => props.theme.font_weight[props.fontWeight] || props.fontWeight || props.theme.font_weight.normal};
  text-align: ${props => props.textAlign || "left"};
  color: ${props => props.theme.colors[props.color] || props.color || props.theme.colors.text};
  display: ${props => props.display};
  width: ${props => props.width || "auto"};
  font-family: ${props => props.fontFamily || props.theme.font_family.default};
  text-transform: ${props => props.textTransform || "none"};;
  ${props => props.fontSizeXS && css`
      @media screen and (max-width: ${props.theme.breakpoints[0]}) {
         font-size: ${props => props.theme.font_size[props.fontSizeXS] || props.fontSizeXS || props.theme.font_size[2]};
         line-height: ${props => props.lineHeightXS || 1.3};            
      }
  `}
`;

Text.propTypes = {
  textAlign: PropTypes.oneOf(["left", "right", "center"]),
  color: PropTypes.any,
  fontSize: PropTypes.any,
  fontSizeXS: PropTypes.any,
  lineHeight: PropTypes.any,
  lineHeightXS: PropTypes.any,
  fontWeight: PropTypes.any,
  display: PropTypes.any,
  width: PropTypes.any,
  fontFamily: PropTypes.any,
  textTransform: PropTypes.any,
};


export const Title = styled(Text)`
    line-height: ${props => props.lineHeight || 1.3};
    font-family: ${props => props.fontFamily || props.theme.font_family.title};
    font-weight: ${props => props.theme.font_weight[props.fontWeight] || props.fontWeight || props.theme.font_weight.bold};
`;

Title.propTypes = {
  textAlign: PropTypes.oneOf(["left", "right", "center"]),
  color: PropTypes.any,
  fontSize: PropTypes.any,
  fontSizeXS: PropTypes.any,
  lineHeight: PropTypes.any,
  fontWeight: PropTypes.any,
  display: PropTypes.any,
  width: PropTypes.any,
  fontFamily: PropTypes.any,
  textTransform: PropTypes.any,
};

export const Title1 = styled(Text)`
    line-height: ${props => props.lineHeight || 1};
    font-family: ${props => props.fontFamily || props.theme.font_family.header};
    font-weight: ${props => props.theme.font_weight[props.fontWeight] || props.fontWeight || props.theme.font_weight.normal};
    letter-spacing: 0.47px;
`;

Title1.propTypes = {
  textAlign: PropTypes.oneOf(["left", "right", "center"]),
  color: PropTypes.any,
  fontSize: PropTypes.any,
  fontSizeXS: PropTypes.any,
  lineHeight: PropTypes.any,
  fontWeight: PropTypes.any,
  display: PropTypes.any,
  width: PropTypes.any,
  fontFamily: PropTypes.any,
  textTransform: PropTypes.any,
};


