import {reaction} from "dacho";

let types = {
  FETCH_PLANS: null,
  FETCH_PLANS_SUCCESS: null,
  FETCH_PLANS_FAILED: null
};

types = reaction(types, "PLANS/");

export default types;
