import {call, put, takeLatest} from 'redux-saga/effects';
import * as api from './api';
import * as plansActions from "./plans/actions";
import plansActionTypes from "./plans/actionTypes";
import {recordFromJS} from "../lib/immutable";

/**
 * fetch plans saga
 * @param action
 * @return {IterableIterator<*>}
 */
function* fetchPlansSaga(action) {
  try {
    const plans = yield call(api.fetchPlans);
    yield put(plansActions.fetchPlansSuccess({plans: recordFromJS(plans)}));
  } catch (error) {
    yield put(plansActions.fetchPlansFailed({error}));
  }
}

function* rootSaga() {
  // get only latest => disable concurrent
  yield takeLatest(plansActionTypes.FETCH_PLANS, fetchPlansSaga);
}

export default rootSaga;
