import actionTypes from "./actionTypes";

/**
 * fetch plans
 * @return {{type: null, payload: {id: *}}}
 */
export const fetchPlans = () => ({
  type: actionTypes.FETCH_PLANS
});

/**
 * fetch plans success
 * @param plans
 * @return {{type: null, payload: {plans: *}}}
 */
export const fetchPlansSuccess = ({plans}) => ({
  type: actionTypes.FETCH_PLANS_SUCCESS,
  payload: {
    plans
  }
});

/**
 * fetch plans failed
 * @param error
 * @return {{type: null, payload: {error: *}}}
 */
export const fetchPlansFailed = ({error}) => ({
  type: actionTypes.FETCH_PLANS_FAILED,
  payload: {
    error
  }
});
