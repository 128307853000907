import initialState from "./initial";
import actionTypes from "./actionTypes";

export default (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.FETCH_PLANS:
      return state
        .set("pending", true)
        .set("error", null)
        .set("plans", initialState.plans);

    case actionTypes.FETCH_PLANS_SUCCESS:
      const {plans} = action.payload;
      return state
        .set("pending", false)
        .set("plans", plans);

    case actionTypes.FETCH_PLANS_FAILED:
      const {error} = action.payload;
      return state
        .set("pending", false)
        .set("error", error);

    default:
      return state;
  }
};
