import {fitplan, backend} from "../lib/api";
import getSymbolFromCurrency from 'currency-symbol-map'
import {getEnv} from "../lib/env";
/**
 * format price
 * @param price
 * @return {number}
 */
export const formatPrice = price => Math.floor(price * 100) / 100;

/**
 * fetch plan
 * fitch plan from stripe api
 * @return {*}
 */
export const fetchPlans = () => backend
  .get(`/fitplan-server/web/stripe/payment/plans?productId=prod_F4yaHqPiPTQHfE${(window.getIp && window.getIp()) ? `&ip=${window.getIp()}` : ``}`,
  {
    headers: {
      Authorization: `Bearer ${window.getAccessToken()}`,
    }
  })
  .then(({data: {error, result}}) => {
    return result.filter(plan => plan.active).map(plan => {
      // Main display text for payment frequency
      let intervalDisplayText = getEnv('LANGUAGE') === 'es' ? 'Trimestral' : 'Quarterly';
      let intervalDisplaySubText = getEnv('LANGUAGE') === 'es' ? 'Cada 3 meses' : 'Billed once per quarter';
      let chargedFrequencyDisplay = "quarterly";
      let priceMonthlyDisplayText = `${formatPrice(plan.amount / 300)}`;
      if (plan.interval === 'year') {
        intervalDisplayText = getEnv('LANGUAGE') === 'es' ? 'Anual' : 'Yearly';
        intervalDisplaySubText = getEnv('LANGUAGE') === 'es' ? 'Anual' : 'Billed once per year';
        chargedFrequencyDisplay = "yearly";
        priceMonthlyDisplayText = `${formatPrice(plan.amount / 1200)}`;
      } else if (plan.interval === 'month' && plan.intervalCount === 1) {
        intervalDisplayText = getEnv('LANGUAGE') === 'es' ? 'Mensual' : 'Monthly';
        intervalDisplaySubText = getEnv('LANGUAGE') === 'es' ? 'Cada al mes' : 'Billed once a month';
        chargedFrequencyDisplay = "monthly";
        priceMonthlyDisplayText = `${formatPrice(plan.amount / 100)}`;
      }
      plan.priceDisplayText = `${getSymbolFromCurrency(plan.currency.toUpperCase())}${formatPrice(plan.amount / 100)}`;
      plan.priceMonthlyDisplayText = `${getSymbolFromCurrency(plan.currency.toUpperCase())}${priceMonthlyDisplayText}`;
      plan.chargedFrequencyDisplay = chargedFrequencyDisplay;
      plan.intervalDisplayText = intervalDisplayText;
      plan.intervalDisplaySubText = intervalDisplaySubText;

      return plan;
    })
  });

/**
 * validate coupon
 * @param coupon
 * @return {*}
 */
export const validateCoupon = coupon => fitplan
  .post(`/coupon`, {coupon})
  .then(({data: {error, result}}) => error || !result ? Promise.reject(new Error(error.message)) : result);


/**
 * submit stripe
 * @param stripeToken
 * @param planId
 * @param couponCode
 * @param advertiserId
 * @param accessToken
 * @return {*}
 */
export const submitStripe = ({stripeToken, planId, couponCode, advertiserId, accessToken}) => backend
  .post("/fitplan-server/web/stripe/submit/payment", {
    stripePlanId: planId,
    stripeToken,
    couponCode,
    advertisementId: advertiserId,
    advertisementEnabled: 1
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  })
  .then(({data: {error, result}}) => error || !result ? Promise.reject(new Error(error.message)) : result);