import styled, {css} from "styled-components";
import {Box} from "@rebass/grid";

/**
 * background
 */
export const BackgroundBox = styled(Box)`
   ${props => css`
     background-image: linear-gradient(180deg, 
        ${props.theme.colors.background_start}, 
        ${props.theme.colors.background_end}
     );
     background-color: ${props.theme.colors.background_start};
  `}
`;
