import styled from "styled-components";
import PropTypes from "prop-types";

export const Image = styled.img`  
    vertical-align: ${props => props.verticalAlign || "bottom"};
`;

Image.propTypes = {
  verticalAlign: PropTypes.string
};

