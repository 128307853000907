/**
 * prefixed animation duration
 * @param animationDuration
 * @return {{animationDuration: string, msAnimationDuration: string, WebkitAnimationDuration: string, MozAnimationDuration: string, OAnimationDuration: string}}
 */
export const getAnimationDuration = (animationDuration) => ({
  animationDuration: `${animationDuration}ms`,
  msAnimationDuration: `${animationDuration}ms`,
  WebkitAnimationDuration: `${animationDuration}ms`,
  MozAnimationDuration: `${animationDuration}ms`,
  OAnimationDuration: `${animationDuration}ms`
});


export const fixVHforMobile = () => {
  console.log("fixVHforMobile");
  // We listen to the resize event
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, 500);
};
