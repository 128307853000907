import React from 'react';
import {css} from 'emotion';
import PropTypes from 'prop-types';
import SaveCoupon from './Save50percentCoupon';
import "./newSubscribeButtons.scss";

const NewSubscribeButtons = props => (
  // Kept it as a div, with role button and a tabIndex as using <button> breaks all the styling
  <div
    className={
      props.selected
        ? `subscribe__block-new-version selected ` +
        css`
            margin: auto;
          `
        : 'subscribe__block-new-version ' +
        css`
            margin: auto;
          `
    }
    tabIndex={0}
    role="button"
    onClick={props.handleClick}
  >
    <div className="subscribe__block-left-content">
      <h2 className="subscribe__block-duration">
        {props.text}
        {props.text.toLowerCase() === 'yearly' ? (
          <span>
            <SaveCoupon save={props.save}/>
          </span>
        ) : null}
      </h2>
      <h2
        className={
          props.selected
            ? `subscribe__block-billing selected`
            : 'subscribe__block-billing'
        }
      >
        {props.subText}
      </h2>
    </div>
    <div className="subscribe__block-price">
      <h2>
        {props.price}
        {getCurrencyText(props.currency) && <span
          className={css`
            margin-left: 2px;
            vertical-align: super;
            font-weight: 400;
            font-family: 'Barlow',sans-serif;
            font-size: 12px;
            @media (min-width: 350px) {
              font-size: 14px;
            }
          `}
        >
          {getCurrencyText(props.currency)}
        </span>}
      </h2>
    </div>
    <div className="subscribe__block-overlay"/>
  </div>
);

const getCurrencyText = (currency) => {
  switch (currency.toLowerCase()) {
    case 'aud':
      return 'AUD';
    case 'cad':
      return 'CAD';
    default:
      return;
  }
}

NewSubscribeButtons.defaultProps = {
  selected: false,
  text: 'Yearly',
  subText: 'Pay $99.99 yearly',
  save: 'SAVE 50',
  price: 99.99,
  currency: 'usd',
  handleClick: () => null,
};

NewSubscribeButtons.propTypes = {
  selected: PropTypes.bool,
  text: PropTypes.string,
  subText: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.string,
  save: PropTypes.string,
  handleClick: PropTypes.func,
};

export default NewSubscribeButtons;
