import React, {Component} from 'react';
import {css} from 'emotion';
import PropTypes from 'prop-types';
import Spinner from './spinner';
import "./button.scss";

class Button extends Component {
  render() {
    const buttonStates = {
      success: (
        <svg viewBox="0 0 33 25"
             className={css`
              width: 32px;
              height: 24px;
             `}>
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M28.08 1.313L11.298 18.095 5.184 11.98a2.667 2.667 0 0 0-3.77 3.77l8 8a2.667 2.667 0 0 0 3.77 0L31.85 5.085a2.667 2.667 0 0 0-3.77-3.77z"
          />
        </svg>
      ),
      fail: (
        <svg viewBox="0 0 23 23"
             className={css`
              width: 22px;
              height: 24px;
             `}>
          <path fill="#FFF"
                fillRule="evenodd"
                d="M21.992 1.171a2.182 2.182 0 0 0-3.085 0l-7.184 7.185-7.185-7.185a2.182 2.182 0 0 0-3.085 3.085l7.185 7.185-7.185 7.185a2.182 2.182 0 1 0 3.085 3.085l7.185-7.185 7.184 7.185a2.182 2.182 0 0 0 3.085-3.085l-7.184-7.185 7.184-7.185a2.182 2.182 0 0 0 0-3.085z"/>
        </svg>
      ),
      loading: <Spinner/>,
      default: (
        <span className="subscribe-now-button__text">
          {this.props.buttonText}
        </span>
      ),
      disabled: (
        <span className="subscribe-now-button__text">
          {this.props.buttonText}
        </span>
      ),
    };

    const containerClass = `subscribe-now-button__container ${this.props.buttonState === 'fail' ? 'fail' : ''}${this.props.buttonState === 'disabled' ? 'disabled' : ''}`;
    return (
      <button className={containerClass}
              onClick={this.props.buttonState === 'disabled' ? e => e.preventDefault() : this.props.handleClick}
              onKeyDown={this.props.buttonState === 'disabled' ? e => e.preventDefault() : this.props.handleClick}>
        {buttonStates[this.props.buttonState]}
      </button>
    );
  }
}

Button.defaultProps = {
  handleClick: () => null,
  buttonState: 'default',
};

Button.propTypes = {
  handleClick: PropTypes.func,
  buttonState: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};

export default Button;
