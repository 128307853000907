import React from "react";
import {Box, Flex} from "@rebass/grid";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {Text} from "../text/Text";
import {ButtonPlain} from "../elements/Button";

/**
 * offer box design
 * @param name
 * @param price
 * @param description
 * @param isLeft
 * @param isRight
 * @param isMid
 * @param onClick
 * @return {*}
 * @constructor
 */
export const OfferBox = ({name, price, description, isLeft, isRight, isMid, link, localized}) => (
  <Box flex={isMid ? 1.5 : 1}
       bg={isMid ? "offer_main_bg" : "offer_bg"}
       ml={isLeft ? 2 : 0}
       mr={isRight ? 2 : 0}
       mb={isLeft || isRight ? 2 : 0}
       p={2}
       css={{
         borderTopLeftRadius: isLeft || isMid ? "8px" : "0px",
         borderBottomLeftRadius: isLeft || isMid ? "8px" : "0px",
         borderTopRightRadius: isRight || isMid ? "8px" : "0px",
         borderBottomRightRadius: isRight || isMid ? "8px" : "0px",
       }}
       style={{minHeight: "130px"}}>
    <ButtonPlain as={link ? Link : "span"} to={link} width="100%">
      <Flex flexDirection="column" alignItems="stretch">
        {isMid ? (
          <Box pt={1} pb={2}>
            <Text fontSize={5}
                  fontSizeXS={3}
                  textTransform="uppercase"
                  color="offer_main_title"
                  fontWeight="bold"
                  textAlign="center">
              {localized.most_popular}
            </Text>
          </Box>
        ) : null}
        <Box bg={isMid ? "offer_main_inner" : "transparent"} px={isMid ? 1 : 0} py={isMid ? 3 : 0} css={{
          borderRadius: "4px"
        }}>
          <Flex flexDirection="column" alignItems="center">
            <Box>
              <Text fontSize={isMid ? 8 : 7} fontSizeXS={isMid ? 7 : 6} textTransform="uppercase" textAlign="center">
                {name}
              </Text>
            </Box>
            <Box>
              <Text fontSize={isMid ? 10 : 9} fontSizeXS={isMid ? 8 : 7} textAlign="center">
                {price}<Text as="span" fontSize={isMid ? 3 : 0}> / {localized.month}</Text>
              </Text>
            </Box>
            <Box>
              <Text color={isMid ? null : "offer_subtitle"} fontSize={1} textAlign="center">
                {description}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </ButtonPlain>
  </Box>
);

OfferBox.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  description: PropTypes.string,
  isLeft: PropTypes.bool,
  isRight: PropTypes.bool,
  isMid: PropTypes.bool,
  onClick: PropTypes.func
};
