import React from "react";
import {Box, Flex} from "@rebass/grid";
import PropTypes from "prop-types";
import {Title} from "../text/Text";
import {Image} from "../elements/Image";
import imageStar from "../../../images/star.png";

/**
 * reate box
 * @return {*}
 * @constructor
 */
export const RateBox = ({text, by}) => (
  <Box m={3}>
      <Flex flexDirection="column" alignItems="center">
        <Box mb={2}>
          {[1, 2, 3, 4, 5].map(index => (
            <Box key={`star-${index}`} as="span" p={1}>
                <Image alt="star" src={imageStar} width="16px"/>
            </Box>
          ))}
        </Box>
        <Box px={1}>
          <Title fontSize={6}
                 fontWeight="normal"
                 textAlign="center"
                 lineHeight={1.6}>
            “{text}”
          </Title>
          <Title fontSize={2}
                 fontWeight="normal"
                 textAlign="center"
                 lineHeight={1.6}
                 textTransform="uppercase"
                 color="text_light">
            — {by}
          </Title>
        </Box>
      </Flex>
  </Box>
);


RateBox.propTypes = {
  text: PropTypes.string,
  by: PropTypes.string
};
