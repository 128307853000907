import Promise from "bluebird";
import {getEnv} from "./env";

const isLoggerEnabled = getEnv("REDUX_LOGGER_ENABLED");

/**
 * a utility to check if a value is a Promise or not
 * @param value
 */
export const isPromise = value => value !== null && typeof value === 'object' && typeof value.then === 'function';

/**
 * a utility to resolve an array of promises sequentially
 * @param {Array} array any array of promises, values or a mix
 * @param {Function} func an optional function to create a promises from values if not presented Promise.resolve will be used
 * @return {Promise}
 */
export const Sequence = (array, func) => array.reduce((a, b) => a.then(() => {
  let value = (typeof func === "function") ? func(b) : b;
  if (typeof value === "function") {
    value = value();
  }
  return isPromise(value) ? value : Promise.resolve(value);
}), Promise.resolve());


/**
 * Promise error redux middleware, The error middleware serves to dispatch the initial pending promise to the promise
 * middleware, but adds a `catch`, yopu can include include a property in `meta and evaluate that property to check if
 * this error will be handled locally
 * @return {function(*): Function}
 */
export const errorMiddleware = () => next => action => {
  // If not a promise, continue on
  if (!isPromise(action.payload)) {
    return next(action);
  }

  if (!action.meta || !action.meta.localError) {
    // Dispatch initial pending promise, but catch any errors
    return next(action).catch(error => {
      if (isLoggerEnabled) {
        console.log(`${action.type} unhandled rejection caught at middleware with reason: ${JSON.stringify(error.message)}.`);
      }
      return error;
    });
  }

  return next(action);
};
