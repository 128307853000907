import React, {Component} from "react";
import {Box, Flex} from "@rebass/grid";
import {Text} from "../styled/text/Text";

const errorTexts = [
  // eslint-disable-next-line
  "(·.·) ",
  // eslint-disable-next-line
  "(˚Δ˚) ",
  // eslint-disable-next-line
  "(·_·) ",
  // eslint-disable-next-line
  "(^_^)",
  // eslint-disable-next-line
  " (>_<) ",
  // eslint-disable-next-line
  "(o^^)o",
  // eslint-disable-next-line
  " (;-;) ",
  // eslint-disable-next-line
  "(≥o≤) ",
  // eslint-disable-next-line
  "(^-^*) ",
  // eslint-disable-next-line
  "(='X'=)"
];

class NotFoundRoute extends Component {
  render() {
    const errorText = errorTexts[Math.floor(Math.random() * errorTexts.length - 1)];
    const {localized} = this.props;
    return (
      <Flex alignItems="center" justifyContent="center" css={{
        minHeight: "100vh",
        overflow: "hidden"
      }}>
        <Box p={2}>
          <Text fontSize={10} fontWeight="bold" textAlign="center" as="h1">
            {localized.error.title} {errorText}
          </Text>
          <Text fontSize={8} textAlign={"center"}>
            {localized.error.does_not_exist}<br/>
            {localized.error.return_link}
          </Text>
        </Box>
      </Flex>
    );
  }
}

export default NotFoundRoute;
