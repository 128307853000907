export default {
  name: "Default",
  maxWidth: "550px",
  // font family
  font_family: {
    default: "'Source Sans Pro', sans-serif",
    title: "Barlow, sans-serif",
    header: "'League Gothic', sans-serif"
  },
  // font size
  font_size: [
    "9px",
    "11px",
    "12px",
    "13px",
    "14px",
    "15px",
    "17px",
    "18px",
    "21px",
    "22px",
    "31px",
    "43px"
  ],
  font_weight: {
    bold: "700",
    normal: "400",
    light: "300",
  },
  // colors
  colors: {
    preloader: "#F7F7F7",
    background_start: "#030A11",
    background_end: "#1C3752",
    text: "#FFFFFF",
    text_light: "#95979A",
    offer_bg: "#5c6369",
    offer_subtitle: "#c9c9c9",
    offer_main_bg: "#FFFFFF",
    offer_main_inner: "#12b587",
    offer_main_title: "#000000",
    icon_light: "#65686A",
    button: "transparent",
    button_text: "#FFF",
  },
  // spacing
  space: [
    0,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  // media query break point
  breakpoints: [
    "320px", // iphone 5
    "375px", // iphone 6/7/8/X,
    "1024px" // others
  ]
};
