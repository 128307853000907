import {applyMiddleware, combineReducers, createStore} from "redux";
import {createTransform, persistReducer, persistStore} from 'redux-persist';
import storage from "redux-persist/lib/storage";
import {sagaMiddleware} from "./middleware";
import rootSaga from "./sagas";
import {mergeDeepRecord} from "../lib/immutable";

export const configureStore = (onComplete, initialState, reducers, middleware) => {
  const store = createStore(
    persistReducer({
      key: 'fitplan:paywall',
      version: 2.1,
      storage, // defaults to localStorage for web and AsyncStorage for react-native
      blacklist: ['payment'],
      migrate: () => Promise.resolve(initialState),
      transforms: [createTransform(
        // transform state on its way to being serialized and persisted.
        (inboundState, key) => initialState[key] && initialState[key].toJS ? inboundState.toJS() : inboundState,
        // transform state being rehydrated
        (outboundState, key) => initialState[key] && initialState[key].toJS ? mergeDeepRecord(initialState[key], outboundState) : outboundState
      )]
    }, combineReducers(reducers)),
    applyMiddleware(...middleware)
  );
  persistStore(store, null, onComplete);
  // run the saga
  sagaMiddleware.run(rootSaga);
  return store;
};
