import createSagaMiddleware from 'redux-saga'
import {createLogger} from "redux-logger";
import {errorMiddleware} from "../lib/promises";
import {getEnv} from "../lib/env";

export const sagaMiddleware = createSagaMiddleware();

// check if enable logger middleware
const isLoggerEnabled = getEnv("REDUX_LOGGER_ENABLED") === "true";

/**
 * shared middleware
 */
export default [
  sagaMiddleware,
  errorMiddleware
].concat(isLoggerEnabled ? createLogger() : []);
