import axios from "axios";
import {getEnv} from "./env";

export const stripe = axios.create({
  "baseURL": getEnv("API_STRIPE_URL"),
  "timeout": parseInt(getEnv("API_STRIPE_TIMEOUT")),
  "headers": {}
});


export const fitplan = axios.create({
  "baseURL": getEnv("API_FITPLAN_URL"),
  "timeout": parseInt(getEnv("API_FITPLAN_TIMEOUT")),
  "headers": {
    "X-Requested-With": "XMLHttpRequest"
  }
});

export const backend = axios.create({
  "baseURL": getEnv("API_BACKEND_URL"),
  "timeout": parseInt(getEnv("API_BACKEND_TIMEOUT")),
  "headers": {
    "X-Requested-With": "XMLHttpRequest"
  }
});
