import React, {Component} from 'react';
import {Provider} from "react-redux";
import {Box, Flex} from "@rebass/grid";
import {ThemeProvider} from "styled-components";
import {Elements, StripeProvider} from "react-stripe-elements";
import configureWebStore, {history} from "../redux/configureWebStore";
import {Text} from "./styled/text/Text";
import {Preloader} from "./styled/others/Preloader";
import theme from "../theme";
import {fixVHforMobile} from "../lib/style";
import {getEnv} from "../lib/env";
import {Route, Switch} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import IndexRoute from "./routes/index";
import PaymentRoute from "./routes/payment";
import NotFoundRoute from "./routes/404";
import {SentryBoundary} from "./Sentry";
import * as Sentry from '@sentry/browser';
import LocalizedText from './translation';

const testRunning = process.env.REACT_APP_AB_TEST === 'paywall' ? true : false;

const stripeFonts = [{
  src: 'url(https://fonts.gstatic.com/s/barlow/v3/7cHpv4kjgoGqM7E_DMs5ynghnQ.woff2)',
  family: 'Barlow',
  style: 'normal',
}, {
  src: "url(https://fonts.gstatic.com/s/lato/v15/S6uyw4BMUTPHjx4wXiWtFCc.woff2)",
  family: 'Lato'
}];

class App extends Component {
  store = null;
  unmounted = false;
  state = {
    ready: false
  };

  componentDidMount() {
    // wait until app state dehydrate from local storage
    this.store = configureWebStore(() => !this.unmounted && this.setState({ready: true}));
    // fix vh size
    fixVHforMobile();
    // We listen to the resize, orientationchange  event to fix vh unit
    window.addEventListener('orientationchange', fixVHforMobile, false);
    window.addEventListener('resize', fixVHforMobile, false);

    Sentry.init({
        dsn: "https://ef4296c0a54243e090e5f151624f5f05@sentry.io/1467009"
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
    // remove listeners
    window.removeEventListener('orientationchange', fixVHforMobile);
    window.removeEventListener('resize', fixVHforMobile);
  }

  render() {
    const {ready} = this.state;
    const localized = process.env.REACT_APP_LANGUAGE === 'es' ? LocalizedText.es : LocalizedText.en;
    return (
      <SentryBoundary>
        <ThemeProvider theme={theme}>
          {ready ? (
            <Provider store={this.store}>
              <StripeProvider apiKey={getEnv("API_STRIPE_KEY")}>
                <Elements fonts={stripeFonts}>
                  <ConnectedRouter history={history}
                                  onLocationChanged={() => console.log("location changed")}>
                    <Switch>
                      <Route path="/" exact render={(props) => <IndexRoute {...props} testRunning={true} localized={localized} />}/>
                      <Route path="/payment/:planId" exact render={(props) => <PaymentRoute {...props} testRunning={testRunning} localized={localized} />}/>
                      <Route render={(props) => <NotFoundRoute {...props} testRunning={testRunning} localized={localized} />}/>
                    </Switch>
                  </ConnectedRouter>
                </Elements>
              </StripeProvider>
            </Provider>
          ) : (
            <Flex className="vh-for-mobile" alignItems="center" justifyContent="center" css={{
              height: "100vh"
            }}>
              <Box flex={1}>
                <Text textAlign="center">
                  <Preloader size={4}/>
                </Text>
              </Box>
            </Flex>
          )}
        </ThemeProvider>
      </SentryBoundary>
    );
  }
}

export default App;
