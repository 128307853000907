import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';

// Sentry.init({
//  dsn: "https://ef4296c0a54243e090e5f151624f5f05@sentry.io/1467009"
// });
// should have been called before using it here
// ideally before even rendering your react app

export class SentryBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, eventId: null };
    }

    componentDidCatch(error, errorInfo) {
      this.setState({ error });
      Sentry.withScope(scope => {
          scope.setExtras(errorInfo);
          const eventId = Sentry.captureException(error);
          this.setState({eventId})
      });
    }

    onClick = (ev) => {
        ev.preventDefault();
        Sentry.showReportDialog({ eventId: this.state.eventId });
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
              <button onClick={this.onClick}>Report feedback</button>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}